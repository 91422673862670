import React from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { RequestMaintenancePlanWizardDatasourceProps } from '../RequestMaintenancePlanWizard'
import { getTextFieldValue } from '../../../../helpers/layoutServiceHelper'
import { COLORS } from '../../../../constants/themeConstants'
import VehicleGroupItem from './SelectVehicleGroupItem'
import NoResults from '../../../molecules/NoResults/NoResults'
import Button from '../../../atoms/Button/Button'
import { ICONS } from '../../../../constants/iconConstants'
import SpinnerWithLabel from '../../../atoms/SpinnerWithLabel/SpinnerWithLabel'
import i18next from 'i18next'
import { useRequestMaintenancePlan } from '../../../../providers/RequestMaintenancePlanProvider'
import { Vehicle } from '../../../../services/rest/ecommerce/dafCompanyVehicles'

interface SelectVehicleGroupProps {
  datasource: RequestMaintenancePlanWizardDatasourceProps
}

const SelectVehicleGroup = ({ datasource }: SelectVehicleGroupProps) => {
  const { isFetchingVehicles, groupedVehicles } = useRequestMaintenancePlan()

  const countAllVehicles = (groupedVehicles?: Record<string, Vehicle[]>) => {
    if (!groupedVehicles) return 0

    return Object.values(groupedVehicles).reduce(
      (total, group) => total + group.length,
      0
    )
  }

  return (
    <Box>
      {groupedVehicles &&
        Object.keys(groupedVehicles).length > 0 &&
        !isFetchingVehicles && (
          <>
            <Box>
              <Text
                variant="heading2"
                color={COLORS.BLACK}
                sx={{ fontSize: [4, 4, 4, 4] }}
              >
                {i18next.t(getTextFieldValue(datasource.stepOneTitle), {
                  numberOfVehicles: countAllVehicles(groupedVehicles),
                })}
              </Text>
            </Box>
            <Box>
              <Text variant="bodyCompact" color={COLORS.GRAY}>
                {getTextFieldValue(datasource.stepOneSubtitle)}
              </Text>
            </Box>
            <Box sx={{ marginBlockStart: 6 }}>
              {Object.entries(groupedVehicles).map(([key, group]) => (
                <VehicleGroupItem
                  key={key}
                  group={group}
                  noOfVehicleText={getTextFieldValue(datasource.noOfVehiclesText)}
                  enterInformationText={getTextFieldValue(
                    datasource.enterInformationButtonText
                  )}
                />
              ))}
            </Box>
          </>
        )}
      {!groupedVehicles && (
        <Box sx={{ marginBlock: 5 }}>
          <NoResults
            description={getTextFieldValue(datasource.noResultsDescription)}
            title={getTextFieldValue(datasource.noResultsTitle)}
          >
            <Button
              as="a"
              href={datasource.backToMyFleetButton.url}
              variant="primary"
              icon={ICONS.ARROW_LEFT}
              iconPosition="start"
            >
              {datasource.backToMyFleetButton.text}
            </Button>
          </NoResults>
        </Box>
      )}

      {isFetchingVehicles && (
        <Flex
          sx={{
            width: '100%',
            height: '100%',
            paddingY: 5,
          }}
        >
          <SpinnerWithLabel
            label={i18next.t('commonLabels.loading')}
            sx={{
              width: 'max-content',
              position: 'sticky',
              top: '50%',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          />
        </Flex>
      )}
    </Box>
  )
}

export default SelectVehicleGroup
