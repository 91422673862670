import React from 'react'
import { Datasource, LinkField, TextField } from '../../../types/layoutService'
import withData from '../../../enhancers/withData'
import SelectVehicleGroup from './SelectVehicleGroup/SelectVehicleGroup'
import { Box } from 'theme-ui'
import Button from '../../atoms/Button/Button'
import { ICONS } from '../../../constants/iconConstants'
import ProgressSteps from '../../atoms/ProgressSteps/ProgressSteps'
import { COLORS } from '../../../constants/themeConstants'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import {
  RequestMaintenancePlanProvider,
  useRequestMaintenancePlan,
} from '../../../providers/RequestMaintenancePlanProvider'
import UsageInformationOverview from './UsageInformation/UsageInformationOverview'
import MaintenanceHistoryOverview from './MaintenanceHistory/MaintenanceHistoryOverview'

export interface RequestMaintenancePlanWizardDatasourceProps {
  stepOneTitle: TextField
  stepOneSubtitle: TextField
  stepOneProgressBarTitle: TextField
  noOfVehiclesText: TextField
  enterInformationButtonText: TextField
  stepTwoTitle: TextField
  stepTwoSubtitle: TextField
  stepTwoProgressBarTitle: TextField
  previousButton: TextField
  enterMaintenanceHistoryButton: TextField
  vin: TextField
  copyToAllVehiclesButton: TextField
  stepThreeProgressBarTitle: TextField
  backToMyFleetButton: LinkField
  noResultsDescription: TextField
  noResultsTitle: TextField
  stepThreeTitle: TextField
  stepThreeSubtitle: TextField
  noInputNeeded: TextField
  requestMaintenancePlanButton: TextField
  successMessage: TextField
  errorMessage: TextField
  progressTitle: TextField
  progressDescription: TextField
  progressActionText: TextField
  progressText: TextField
  fetchingMaintenanceHistoryText: TextField
}

const RequestMaintenancePlanWizard = ({
  datasource,
}: Datasource<RequestMaintenancePlanWizardDatasourceProps>) => {
  const { activeStep, isFetchingVehicles } = useRequestMaintenancePlan()
  return (
    <>
      <Box
        sx={{
          borderBottom: '1px solid',
          borderColor: COLORS.MEDIUM_GRAY,
          paddingY: [3, 3, 3, 4],
          marginBottom: [4, 4, 4, 6],
        }}
      >
        <ProgressSteps
          activeStep={activeStep}
          steps={[
            { label: getTextFieldValue(datasource.stepOneProgressBarTitle) },
            { label: getTextFieldValue(datasource.stepTwoProgressBarTitle) },
            { label: getTextFieldValue(datasource.stepThreeProgressBarTitle) },
          ]}
        />
      </Box>
      <Box>
        {activeStep === 1 && <SelectVehicleGroup datasource={datasource} />}
        {activeStep === 2 && <UsageInformationOverview datasource={datasource} />}
        {activeStep === 3 && <MaintenanceHistoryOverview datasource={datasource} />}
      </Box>

      {activeStep === 1 && !isFetchingVehicles && (
        <Box sx={{ display: 'flex', marginBottom: 4 }}>
          <Box sx={{ marginLeft: 'auto' }}>
            <Button
              as="a"
              href={datasource.backToMyFleetButton.url}
              variant="outline"
              icon={ICONS.ARROW_LEFT}
              iconPosition="start"
            >
              {datasource.backToMyFleetButton.text}
            </Button>
          </Box>
        </Box>
      )}
    </>
  )
}

const WrappedRequestMaintenancePlanWizard = (
  props: Datasource<RequestMaintenancePlanWizardDatasourceProps>
) => (
  <RequestMaintenancePlanProvider>
    <RequestMaintenancePlanWizard {...props} />
  </RequestMaintenancePlanProvider>
)

export default withData(WrappedRequestMaintenancePlanWizard)
