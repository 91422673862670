import React from 'react'
import { Box, Flex, Text } from 'theme-ui'
import i18next from 'i18next'
import { COLORS } from '../../../constants/themeConstants'

interface ProgressIndicatorProps {
  total: number
  progress: number
  actionText: string
}

const ProgressIndicator: React.FC<ProgressIndicatorProps> = ({
  total,
  progress,
  actionText,
}) => {
  const progressPercentage = (1 - progress / total) * 100
  return (
    <Flex sx={{ flexDirection: 'column', gap: 2, width: '100%' }}>
      <Flex sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Text color={COLORS.DARK} sx={{ fontSize: 2, fontWeight: 'bold' }}>
          {i18next.t('commonLabels.progressLabel')}
        </Text>
        <Text color={COLORS.GRAY} sx={{ fontSize: 2 }}>
          {`${total - progress} / ${total} ${actionText}`}
        </Text>
      </Flex>
      <Box
        sx={{
          width: '100%',
          height: '10px',
          backgroundColor: COLORS.MEDIUM_GRAY,
          borderRadius: 8,
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            width: `${progressPercentage}%`,
            height: '100%',
            backgroundColor: COLORS.DAF_DARK_BLUE,
            transition: 'width 0.2s ease-in-out',
          }}
        />
      </Box>
    </Flex>
  )
}

export default ProgressIndicator
