import React, {
  createContext,
  Dispatch,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import { useIsMutating, useMutation, useQuery } from '@tanstack/react-query'
import useAxiosClient from '../hooks/services/rest/core/useAxiosClient'
import { AxiosClientType } from '../providers/AxiosClientProvider'
import { UserContext } from '../providers/UserProvider'
import { ActiveStoreProviderContext } from '../providers/ActiveStoreProvider'
import fetchDafCompanyVehicles, {
  DafCompanyVehiclesData,
  Vehicle,
} from '../services/rest/ecommerce/dafCompanyVehicles'
import { DafDefaultQueryParams } from '../types/userProps'
import filterAndGroupVehiclesForMaintenancePlan from '../helpers/vehicleHelper'
import fetchDmscVehicleOptions, {
  VehicleOption,
} from '../services/rest/dmsc/dmscVehicleOptions'
import useSitecoreContext from '../hooks/useSitecoreContext'
import {
  DMSC_OPTION_DOUBLE,
  DMSC_OPTION_LIST,
  INSPECTION_INTERVAL_UK,
  MAINTENANCE_PACKAGE_UK,
  MAINTENANCE_PLAN_REQUEST_EVENT,
} from '../constants/dmscConstants'
import {
  URL_REQUEST_MAINTENANCE_PLAN_MAINTENANCE_HISTORY,
  URL_REQUEST_MAINTENANCE_PLAN_OVERVIEW,
  URL_REQUEST_MAINTENANCE_PLAN_USAGE_INFORMATION,
  URL_STEP,
  URL_VEHICLE_TYPE,
} from '../constants/urlConstants'
import { scrollTo } from '../helpers/dom'
import { getQueryParam } from '../helpers/urlHelper'
import DMSCServiceJobs, {
  DMSCServiceJobsProps,
  ServiceJobsEntity,
  ServiceJobsResponse,
  ServiceJobsResponseValue,
} from '../services/rest/dmsc/DMSCServiceJobs'
import { reverseFormatNumber } from '../helpers/numberFormatHelper'
import VehicleServiceJobs, {
  VehicleServiceJobsProps,
} from '../services/rest/serviceJobs/vehicleServiceJobs'
import {
  MUTATION_KEY_POST_DMSC_VEHICLE_OPTIONS,
  MUTATION_KEY_POST_SERVICE_JOBS,
  QUERY_KEY_DAF_COMPANY_VEHICLES,
} from '../constants/queryKeyConstants'

interface RequestMaintenancePlanProviderProps {
  children: React.ReactNode
}

interface RequestMaintenancePlanContextType {
  activeStep: number
  dafCompanyVehiclesData: DafCompanyVehiclesData | undefined
  groupedVehicles: Record<string, Vehicle[]> | undefined
  isFetchingVehicleOptions: boolean
  selectedVehicleType: string | undefined
  vehicleOptions: VehicleOption[] | undefined
  setUsageInformationFormData: Dispatch<React.SetStateAction<any>>
  isLoadingServiceJobs: boolean
  filteredServiceJobs: ServiceJobsEntity[] | undefined
  isFetchingVehicles: boolean
  setServiceJobsFormData: Dispatch<React.SetStateAction<any>>
  isPostingServiceJobs: boolean
  serviceJobsFormSubmissionStatus: Record<string, 'success' | 'error'> | undefined
  isMutatingVehicleOptions: number | undefined
  isMutatingServiceJobs: number | undefined
}

const RequestMaintenancePlanContext =
  createContext<RequestMaintenancePlanContextType>({
    activeStep: 1,
    dafCompanyVehiclesData: undefined,
    groupedVehicles: undefined,
    isFetchingVehicleOptions: false,
    selectedVehicleType: undefined,
    vehicleOptions: undefined,
    setUsageInformationFormData: () => null,
    isLoadingServiceJobs: false,
    filteredServiceJobs: undefined,
    isFetchingVehicles: false,
    setServiceJobsFormData: () => null,
    isPostingServiceJobs: false,
    serviceJobsFormSubmissionStatus: undefined,
    isMutatingVehicleOptions: undefined,
    isMutatingServiceJobs: undefined,
  })

interface CustomFormData extends FormData {
  [key: string]: any
  estimatedAnnualDistance: number
}

interface CustomServiceJobsFormData extends FormData {
  [key: string]: any
  vin: string
}

interface ServiceJobsData extends ServiceJobsEntity {
  estimatedDate: string | null
  estimatedMileage: number | null
  executedDate?: string | number
  executedDistance?: string | number
}

export const RequestMaintenancePlanProvider = ({
  children,
}: RequestMaintenancePlanProviderProps) => {
  const [vehicleType, setVehicleType] = useState<string | null>(null)
  const [serviceJobsFormSubmissionStatus, setServiceJobsFormSubmissionStatus] =
    useState<Record<string, 'success' | 'error'>>({})
  const [serviceJobsResponseData, setServiceJobsResponseData] =
    useState<ServiceJobsResponse | null>(null)
  const [serviceJobsFormData, setServiceJobsFormData] =
    useState<CustomFormData | null>(null)
  const [usageInformationFormData, setUsageInformationFormData] =
    useState<CustomFormData | null>(null)
  const [activeStep, setActiveStep] = useState(1)
  const location = useLocation()
  const {
    languageContext: { cultureCode },
  } = useSitecoreContext()
  const client = useAxiosClient(AxiosClientType.CommerceApi)
  const { user } = useContext(UserContext)
  const { actingCompanyId, actingSupplierId, isImpersonated } = useContext(
    ActiveStoreProviderContext
  )
  const [filteredServiceJobs, setFilteredServiceJobs] = useState<
    ServiceJobsEntity[] | undefined
  >(undefined)

  useEffect(() => {
    const step = getQueryParam(URL_STEP)
    const type = getQueryParam(URL_VEHICLE_TYPE)

    if (step === URL_REQUEST_MAINTENANCE_PLAN_OVERVIEW) {
      setActiveStep(1)
    } else if (step === URL_REQUEST_MAINTENANCE_PLAN_USAGE_INFORMATION) {
      setActiveStep(2)
    } else if (step === URL_REQUEST_MAINTENANCE_PLAN_MAINTENANCE_HISTORY) {
      setActiveStep(3)
    }

    if (type) {
      setVehicleType(type)
      // clear form submit status when vehicle type changes
      setServiceJobsFormSubmissionStatus({})
    }

    scrollTo({ top: 0, behavior: 'auto' })
  }, [location.search])

  const dafVehicleDetailQueryParams: DafDefaultQueryParams = {
    customerCompanyId: actingCompanyId,
    supplierCompanyId: actingSupplierId,
    isImpersonated,
  }

  const getDafCompanyVehicles = async () => {
    const { token } = axios.CancelToken.source()
    const response = await fetchDafCompanyVehicles(
      client,
      dafVehicleDetailQueryParams,
      token,
      user
    )
    return response.data
  }

  const { isFetching: isFetchingVehicles, data: dafCompanyVehiclesData } = useQuery({
    queryKey: [QUERY_KEY_DAF_COMPANY_VEHICLES],
    queryFn: getDafCompanyVehicles,
    enabled: !!actingCompanyId && !!actingSupplierId,
  })

  const groupedVehicles = React.useMemo(() => {
    if (!dafCompanyVehiclesData) return {}
    return filterAndGroupVehiclesForMaintenancePlan(
      dafCompanyVehiclesData?.result?.vehicles
    )
  }, [dafCompanyVehiclesData])

  const firstVehicleOfGroup = groupedVehicles?.[vehicleType ?? '']?.[0]

  const getDMSCVehicleOptions = async () => {
    const { token } = axios.CancelToken.source()
    const response = await fetchDmscVehicleOptions(
      dafVehicleDetailQueryParams,
      client,
      token,
      firstVehicleOfGroup.chassisNumber,
      cultureCode ?? '',
      user
    )
    return response.data
  }

  const { isLoading: isFetchingVehicleOptions, data: dmscVehicleOptionsData } =
    useQuery({
      queryKey: ['dmscVehicleOptionsData', vehicleType],
      queryFn: () => getDMSCVehicleOptions(),
      enabled: !!actingCompanyId && !!actingSupplierId && !!vehicleType,
    })

  const vehicleOptions = dmscVehicleOptionsData?.result?.vehicleOptions
    ?.filter(
      (vehicleOption) =>
        vehicleOption.option.isAmendable &&
        vehicleOption.option.description !== MAINTENANCE_PACKAGE_UK &&
        vehicleOption.option.description !== INSPECTION_INTERVAL_UK &&
        vehicleOption.option.name !== MAINTENANCE_PACKAGE_UK &&
        vehicleOption.option.name !== INSPECTION_INTERVAL_UK &&
        ((vehicleOption.option.optionType === DMSC_OPTION_LIST &&
          vehicleOption.possibleValues.length > 1) ||
          vehicleOption.option.optionType === DMSC_OPTION_DOUBLE)
    )
    .sort((a, b) => a.option.sequenceNumber - b.option.sequenceNumber)

  const postDMSCVehicleOptions = async (payload: DMSCServiceJobsProps) => {
    const { token } = axios.CancelToken.source()
    const response = await DMSCServiceJobs(
      dafVehicleDetailQueryParams,
      client,
      token,
      payload,
      user
    )

    return response.data
  }

  const { isPending: isLoadingServiceJobs, mutate } = useMutation({
    mutationKey: [MUTATION_KEY_POST_DMSC_VEHICLE_OPTIONS],
    mutationFn: (payload: DMSCServiceJobsProps) => postDMSCVehicleOptions(payload),
    onSuccess: (data) => {
      setServiceJobsResponseData(data)
      if (Array.isArray(data?.result)) {
        const filteredServices = data?.result.filter(
          (serviceJob: ServiceJobsEntity) =>
            serviceJob.requestToUserDate || serviceJob.requestToUserMileage
        )
        if (filteredServices.length > 0) {
          // results in a form to add values to service jobs
          setFilteredServiceJobs(filteredServices)
        }
      }
    },
  })

  const handleVehicleOptionsSubmit = () => {
    // format data so api will accept it
    usageInformationFormData?.map((entry: any) => {
      const formResults = Object.keys(entry)
        .filter((key) => key !== 'estimatedAnnualDistance')
        .filter((key) => key !== 'vin')
        .map((key) => ({
          optionId: +key.replace('s-', ''), // remove s- we put before optionId in the Select Field name
          valueId: +entry[key],
        }))

      // we want to add default values for options that are not filled in by the user
      dmscVehicleOptionsData?.result?.vehicleOptions?.forEach((vehicleOption) => {
        const exists = formResults.some(
          (formResult) => formResult.optionId === vehicleOption.option.optionId
        )
        if (!exists && vehicleOption.optionValue.valueId) {
          formResults.push({
            optionId: vehicleOption.option.optionId,
            valueId: vehicleOption.optionValue.valueId,
          })
        }
      })

      // get chassisNumber by filtering vehicles by vin
      const getChassisNumberFromVehicleByVin =
        dafCompanyVehiclesData?.result?.vehicles.find(
          (vehicle) => vehicle.vin === entry.vin
        )?.chassisNumber

      // create payload to send to api for each vehicle
      const payload = {
        cultureCode: cultureCode ?? '',
        chassisNumber: getChassisNumberFromVehicleByVin ?? '',
        vehicleModelCode: dmscVehicleOptionsData?.result.vehicleModelCode ?? '',
        factorSetId: dmscVehicleOptionsData?.result.factorSetId ?? 0,
        annualDistance: entry.estimatedAnnualDistance
          ? reverseFormatNumber(entry.estimatedAnnualDistance, cultureCode)
          : 0,
        vehicleOptions: formResults,
      }

      mutate(payload)
    })
  }

  useEffect(() => {
    if (usageInformationFormData) {
      handleVehicleOptionsSubmit()
    }
  }, [usageInformationFormData])

  const mergeServiceJobValues = (
    data: ServiceJobsData[] | undefined,
    values: CustomServiceJobsFormData
  ) => {
    if (!data && !values) return []
    // iterate over the keys in the values object
    Object.keys(values)
      .filter((key) => key !== 'vin')
      .forEach((key) => {
        // extract the 'Y' value and property from the key
        const yValue = key.split('-')[0]
        const property = key.split('-')[1]

        // find the corresponding 'Y' value in the data array
        const foundObject = data?.find(
          (item: ServiceJobsData) => item.code === yValue
        )
        // if found, add the corresponding value
        if (foundObject) {
          if (property === 'executedDate') {
            foundObject[property] = values[key]
          } else if (property === 'executedDistance') {
            foundObject[property] = values[key]
              ? reverseFormatNumber(values[key], cultureCode)
              : 0
          }
        }
      })

    return data
  }

  const createPayloadToMutate = (
    data: ServiceJobsResponseValue,
    formData?: CustomServiceJobsFormData
  ) => {
    const result = formData
      ? mergeServiceJobValues(data as any, formData)
      : data?.data?.serviceJobs

    const payload = {
      vin: formData?.vin,
      eventType: MAINTENANCE_PLAN_REQUEST_EVENT,
      serviceJobs: result,
    }

    return payload
  }

  const postVehicleServiceJobs = async (payload: VehicleServiceJobsProps) => {
    const { token } = axios.CancelToken.source()
    const response = await VehicleServiceJobs(
      dafVehicleDetailQueryParams,
      client,
      token,
      payload,
      user
    )

    return response.data
  }

  const { isPending: isPostingServiceJobs, mutate: mutateVehicleServiceJobs } =
    useMutation({
      mutationKey: [MUTATION_KEY_POST_SERVICE_JOBS],
      mutationFn: (payload: VehicleServiceJobsProps) =>
        postVehicleServiceJobs(payload),
      onSuccess: (data, variables) => {
        setServiceJobsFormSubmissionStatus((prevStatus) => ({
          ...prevStatus,
          [variables.vin as string]:
            data?.messages && data.messages.length > 0 ? 'error' : 'success',
        }))
      },
    })

  const handleServiceJobsSubmit = (formData: CustomServiceJobsFormData) => {
    if (serviceJobsResponseData) {
      const payload = createPayloadToMutate(
        serviceJobsResponseData?.result,
        formData
      )
      if (payload) {
        mutateVehicleServiceJobs(payload)
      }
    }
  }

  useEffect(() => {
    if (serviceJobsFormData) {
      serviceJobsFormData.forEach((formData) => {
        // @ts-ignore
        handleServiceJobsSubmit(formData)
      })
    }
  }, [serviceJobsFormData])

  const isMutatingVehicleOptions = useIsMutating({
    mutationKey: [MUTATION_KEY_POST_DMSC_VEHICLE_OPTIONS],
  })

  const isMutatingServiceJobs = useIsMutating({
    mutationKey: [MUTATION_KEY_POST_SERVICE_JOBS],
  })
  return (
    <RequestMaintenancePlanContext.Provider
      value={{
        activeStep,
        dafCompanyVehiclesData,
        groupedVehicles,
        isFetchingVehicleOptions,
        vehicleOptions,
        selectedVehicleType: vehicleType ?? undefined,
        setUsageInformationFormData,
        isLoadingServiceJobs,
        filteredServiceJobs,
        isFetchingVehicles,
        setServiceJobsFormData,
        isPostingServiceJobs,
        serviceJobsFormSubmissionStatus,
        isMutatingVehicleOptions,
        isMutatingServiceJobs,
      }}
    >
      {children}
    </RequestMaintenancePlanContext.Provider>
  )
}

export const useRequestMaintenancePlan = () => {
  return useContext(RequestMaintenancePlanContext)
}
